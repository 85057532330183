import React, {useState} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import AgePopup from '../components/AgePopup';

export default function Signup(props) {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [agePopupState, setAgePopupState] = useState(true);

  function handleSubmit (e) {
    e.preventDefault();

    if (submitting) {
      return;
    }
    setSubmitting(true);

    axios.post('register', {
      name: e.target.name.value,
      username: e.target.username.value,
      email: e.target.email.value,
      password: e.target.password.value,
      password_confirmation: e.target.password_confirmation.value,
      newsletter: e.target.newsletter.checked,
    }).then(() => {
      props.setUser({loggedIn: true, data: null});
      props.refreshUser();
    }).catch(error => {
      console.error(error);
      setError(error.response.data.message);
      setSubmitting(false);
    });
  }

  return (
    <div className="text-center">
      <h1 className="font-black mt-10 text-7xl w-full">
        SPIGA
        <span className="text-spiga-green">.</span>
      </h1>
      <p className="font-bold text-sm uppercase">A <span className="text-spiga-green">free</span> and <span className="text-spiga-green">social</span> sport prediction game</p>
      <div className="mt-10">
        <form className="flex flex-col items-center" onSubmit={handleSubmit}>
          <label htmlFor="name" className="font-bold">Navn</label>
          <input name="name" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" type="text" id="name" autoComplete="name" required />
          <label htmlFor="username" className="font-bold mt-3">Brugernavn</label>
          <input name="username" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" type="text" id="username" autoComplete="username" required />
          <label htmlFor="email" className="font-bold mt-3">E-mail</label>
          <input name="email" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" type="text" id="email" autoComplete="email" required />
          <label htmlFor="password" className="font-bold mt-3">Adgangskode</label>
          <input name="password" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" type="password" id="password" autoComplete="new-password" required />
          <label htmlFor="password_confirmation" className="font-bold mt-3">Bekræft adgangskode</label>
          <input name="password_confirmation" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" type="password" id="password_confirmation" autoComplete="new-password" required />
          <div className="mt-3">
            <input name="newsletter" className="mr-2" type="checkbox" id="newsletter" />
            <label for="newsletter">Send mig nyheder fra SPIGA</label>
          </div>
          <button type="submit" className="border-2 border-gray-300 border-solid font-bold mt-5 p-2 rounded text-xl uppercase">
            Opret bruger
          </button>
        </form>
        <p className="text-rose-600">{error}</p>
        <div className="w-full">
          <Link to="/login">
            <button className="border-2 border-gray-300 border-solid font-bold mt-10 p-2 rounded text-spiga-green text-xl uppercase">
              Log ind som eksisterende bruger
            </button>
          </Link>
        </div>
      </div>
      {agePopupState === true ? <AgePopup setState={setAgePopupState} /> : ''}
    </div>
  );
}
