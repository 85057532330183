import React from 'react';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query';
import MatchItem from './MatchItem';

export default function MatchItems(props) {
  const filtering = props.filterTeamName || props.filterLeagueId || props.filterLeagueName;

  const matchesQuery = useQuery(['matches'], () =>
    axios.get('fixtures').then((response) => response.data)
  );

  function matchFiltered(match) {
    if (!filtering) {
      return true;
    }

    let filtered = true;
    if (props.filterTeamName && match.team_home_name !== props.filterTeamName &&
        match.team_away_name !== props.filterTeamName) {
      filtered = false;
    }
    if (props.filterLeagueId && match.league_id !== props.filterLeagueId) {
      filtered = false;
    }
    if (props.filterLeagueName && match.league_name !== props.filterLeagueName) {
      filtered = false;
    }
    return filtered;
  }

  return (
    <>
      {matchesQuery.isLoading ? (
        'Indlæser kampe...'
      ) : matchesQuery.isError ? (
        'Fejl under indlæsning af kampe: ' + matchesQuery.error.message
      ) : (
        matchesQuery.data.map((match) =>
          matchFiltered(match) ?
            <MatchItem
              key={match.fixture_id}
              user={props.user}
              setBetPopupState={props.setBetPopupState}
              fixture={match.fixture_id}
              league={match.league_id}
              leagueName={match.league_name}
              teamHome={match.team_home_name}
              teamHomeColor1={match.team_home_color_1}
              teamHomeColor2={match.team_home_color_2}
              teamHomeCountry={match.team_home_country}
              teamAway={match.team_away_name}
              teamAwayColor1={match.team_away_color_1}
              teamAwayColor2={match.team_away_color_2}
              teamAwayCountry={match.team_away_country}
              odds1={match.odds_winner_1}
              oddsX={match.odds_winner_x}
              odds2={match.odds_winner_2}
              scoreHome={match.score_home}
              scoreAway={match.score_away}
              time={match.time}
            />
          : ''
        )
      )}
    </>
  );
}
