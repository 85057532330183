import React from 'react';
import {Link} from 'react-router-dom';
import UserImg from './UserImg';
import coin from '../img/coin.svg';

export default function TitleBar(props) {
  return (
    <div className="bg-white flex flex-row items-center pb-2 pt-3 sticky text-center top-0">
      <div className="basis-1/6 font-bold">
        <div className="flex justify-center">
          <Link to="/user">
            <UserImg className="h-8 rounded" userId={props.user.data?.id} />
          </Link>
        </div>
        <p className="leading-none">
          {props.user.data?.coins}
          <img className="h-3 inline mb-0.5 ml-0.5" src={coin} alt="SPIGA coin" />
        </p>
      </div>
      <div className="basis-2/3">
        <Link to="/">
          <h1 className="font-black inline text-3xl">
            SPIGA
            <span className="text-spiga-green">.</span>
          </h1>
        </Link>
      </div>
    </div>
  );
}
