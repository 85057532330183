import React, {useState, useEffect} from 'react';
import {BrowserRouter, Route, Navigate} from 'react-router-dom';
import axios from 'axios';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import AppRoutes from './components/AppRoutes';
import Bet from './routes/Bet';
import Bets from './routes/Bets';
import Home from './routes/Home';
import Login from './routes/Login';
import Notifications from './routes/Notifications';
import Signup from './routes/Signup';
import Tournament from './routes/Tournament';
import Tournaments from './routes/Tournaments';
import TournamentsCreate from './routes/TournamentsCreate';
import VerifyEmail from './routes/VerifyEmail';
import Settings from './routes/Settings';
import User from './routes/User';
import NotFound from './routes/NotFound';
import './App.css';

if (window.location.pathname.endsWith('/') ||
  /[A-Z]/.test(window.location.pathname)) {
  window.history.replaceState({}, '',
    window.location.pathname.replace(/\/+$/, '').toLowerCase());
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  if (!document.cookie.match(/^(.*;)?\s*XSRF-TOKEN\s*=\s*[^;]+(.*)?$/)) {
    refreshCsrf();
  }
  return config;
});
axios.interceptors.response.use(response => response, error => {
  if (error.response?.status === 419) {
    refreshCsrf();
  }
  return Promise.reject(error);
});

let refreshingCsrf = false;
function refreshCsrf() {
  if (!refreshingCsrf) {
    refreshingCsrf = true;
    axios.get('sanctum/csrf-cookie').catch(error => {
      console.log(error);
    }).then(() => {
      refreshingCsrf = false;
    });
  }
}

refreshCsrf();

const queryClient = new QueryClient();

ReactGA.initialize('G-V4VDKNKTGP');

function sendPageview() {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
  });
}

// Disable right-clicking and long-pressing.
window.oncontextmenu = () => false;

function App() {
  const [user, setUser] = useState({loggedIn: null, data: null});

  function refreshUser() {
    axios.get('user').then(response => {
      setUser({loggedIn: true, data: response.data});
    }).catch(error => {
      if (error.response.status === 401) {
        if (user.loggedIn !== false) {
          setUser({loggedIn: false, data: null});
        }
      } else {
        console.log(error);
      }
    });
  }

  useEffect(() => {
    document.body.classList.add('overflow-y-scroll');
    refreshUser();
    setInterval(() => {
      refreshUser();
    }, 5000);
  }, []);

  return (
    <div className="container max-w-xl mx-auto select-none">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AppRoutes sendPageview={sendPageview}>
            <Route path="/" element={user.loggedIn !== false ?
              <Home user={user} setUser={setUser} /> : <Navigate to="/login" />} />
            <Route path="/bet/:id" element={user.loggedIn !== false ?
              <Bet user={user} setUser={setUser} /> : <Navigate to="/login" />} />
            <Route path="/bets" element={user.loggedIn !== false ?
              <Bets user={user} setUser={setUser} /> : <Navigate to="/login" />} />
            <Route path="/login" element={user.loggedIn !== true ?
              <Login user={user} setUser={setUser} refreshUser={refreshUser} /> : <Navigate to="/" />} />
            <Route path="/notifications" element={user.loggedIn !== false ?
              <Notifications user={user} setUser={setUser} /> : <Navigate to="/login" />} />
            <Route path="/settings" element={user.loggedIn !== false ?
              <Settings user={user} setUser={setUser} /> : <Navigate to="/login" />} />
            <Route path="/signup" element={user.loggedIn !== true ?
              <Signup user={user} setUser={setUser} refreshUser={refreshUser} /> : <Navigate to="/" />} />
            <Route path="/tournaments/:id" element={user.loggedIn !== false ?
              <Tournament user={user} setUser={setUser} /> : <Navigate to="/login" />} />
            <Route path="/tournaments" element={user.loggedIn !== false ?
              <Tournaments user={user} setUser={setUser} /> : <Navigate to="/login" />} />
            <Route path="/tournaments/create" element={user.loggedIn !== false ?
              <TournamentsCreate user={user} setUser={setUser} /> : <Navigate to="/login" />} />
            <Route path="/user" element={user.loggedIn !== false ?
              <User user={user} setUser={setUser} /> : <Navigate to="/login" />} />
            <Route path="/users/:id" element={user.loggedIn !== false ?
              <User user={user} setUser={setUser} /> : <Navigate to="/login" />} />
            <Route path="/verify-email/:url" element={
              <VerifyEmail user={user} setUser={setUser} />} />
            <Route path="*" element={
              <NotFound />} />
          </AppRoutes>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
