import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query';
import BetPopup from '../components/BetPopup';
import MatchItems from '../components/MatchItems';
import NavBar from '../components/NavBar';
import TitleBar from '../components/TitleBar';
import Search from '../components/Search';

export default function Tournaments(props) {
  const {id} = useParams();
  const tournament = Number(id);

  const [betPopupState, setBetPopupState] = useState(null);
  const [filterTeamName, setFilterTeamName] = useState(null);

  const tournamentUrl = 'tournaments/' + id;
  const tournamentQuery = useQuery([tournamentUrl], () =>
    axios.get(tournamentUrl).then((response) => response.data)
  );

  function handleSearchClick(type, id, name) {
    removeFilters();

    if (type === 'team') {
      setFilterTeamName(name);
    }
  }

  function removeFilters(event) {
    setFilterTeamName(null);
  }

  return (
    <div>
      <TitleBar user={props.user} setUser={props.setUser} />
      <div className="mb-32 mt-1 mx-3">
        <div className="flex flex-col">
          <div className="flex justify-center w-full">
            <div className="basis-4/5">
              <Search types={['teams']} placeholder="Find hold" onResultClick={handleSearchClick} />
            </div>
          </div>
          {filterTeamName ?
            <div className="mt-3">
              {filterTeamName ?
                <>Viser kun kampe med {filterTeamName}. </>
              : ''}
              <button className="border-2 border-gray-300 border-solid font-bold p-1 rounded text-spiga-green uppercase" onClick={removeFilters}>
                Vis alle kampe
              </button>
            </div>
          : ''}
        </div>
        <div className="mt-4">
          <h2 className="font-black text-xl uppercase">Kampe</h2>
          <div className="flex flex-col items-center mt-2">
            {tournamentQuery.isLoading ? (
                'Indlæser turnering...'
              ) : tournamentQuery.isError ? (
                'Fejl under indlæsning af turnering: ' + tournamentQuery.error.message
              ) : (
                <MatchItems filterTeamName={filterTeamName} filterLeagueId={tournamentQuery.data.league_id} setBetPopupState={setBetPopupState} />
              )}
          </div>
        </div>
      </div>
      {betPopupState !== null ? <BetPopup user={props.user} tournament={tournament} state={betPopupState} setState={setBetPopupState} /> : ''}
      <NavBar />
    </div>
  );
}
