import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

class Login extends React.Component {
  state = {error: null};

  handleSubmit = (e) => {
    e.preventDefault();
    axios.post('login', {
      username: e.target.username.value,
      password: e.target.password.value,
    }).then(() => {
      this.props.setUser({loggedIn: true, data: null});
      this.props.refreshUser();
    }).catch(error => {
      console.log(error);
      this.setState({error: error.response.data.message});
    });
  }

  render() {
    return (
      <div className="text-center">
        <h1 className="font-black mt-10 text-7xl w-full">
          SPIGA
          <span className="text-spiga-green">.</span>
        </h1>
        <p className="font-bold text-sm uppercase">A <span className="text-spiga-green">free</span> and <span className="text-spiga-green">social</span> sport prediction game</p>
        <div className="mt-10">
          <form onSubmit={this.handleSubmit}>
            <div className="w-full">
              <input name="username" className="border-2 border-gray-300 border-solid rounded w-2/3 p-1" type="text" placeholder="Brugernavn eller e-mail" autoComplete="username" required />
            </div>
            <div className="w-full">
              <input name="password" className="border-2 border-gray-300 border-solid mt-3 rounded p-1 w-2/3" type="password" placeholder="Adgangskode" autoComplete="current-password" required />
            </div>
            <div className="w-full">
              <button type="submit" className="border-2 border-gray-300 border-solid font-bold mt-5 p-2 rounded text-xl uppercase">
                Log ind
              </button>
            </div>
          </form>
          <p className="text-rose-600">{this.state.error}</p>
          <div className="w-full">
            <Link to="/signup">
              <button className="border-2 border-gray-300 border-solid font-bold mt-10 p-2 rounded text-spiga-green text-xl uppercase">
                Opret bruger
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
