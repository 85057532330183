import React, {useEffect} from 'react';

// For the following magic to disable scrolling, see
// https://stackoverflow.com/a/4770179

const ScrollingKeys = {
  "ArrowUp": true,
  "ArrowDown": true,
  "ArrowLeft": true,
  "ArrowRight": true,
  "PageUp": true,
  "PageDown": true,
  "Home": true,
  "End": true,
  " ": true,
}

// Modern Chrome requires specifying the listener as non-passive to allow
// calling preventDefault.
let setPassive = false;
try {
  window.addEventListener('', null, Object.defineProperty({}, 'passive', {
    get: () => setPassive = true,
  }));
} catch (error) {}

const wheelEvent = 'onwheel' in document.body ? 'wheel' : 'mousewheel';
const wheelOptions = setPassive ? {passive: false} : false;

const EventListeners = [
  ['DOMMouseScroll', preventDefault, false], // Older Firefox.
  [wheelEvent, preventDefault, wheelOptions], // Modern desktop.
  ['touchmove', preventDefault, wheelOptions], // Mobile.
  ['keydown', keyPreventDefault, false],
];

function preventDefault(event) {
  event.preventDefault();
}

function keyPreventDefault(event) {
  if (ScrollingKeys[event.key]) {
    event.preventDefault();
    return false;
  }
}

export default function Overlay(props) {
  useEffect(() => {
    for (const eventListener of EventListeners) {
      window.addEventListener(...eventListener);
    }

    // This returned callback will be called when unmounting this component.
    return () => {
      for (const eventListener of EventListeners) {
        window.removeEventListener(...eventListener);
      }
    };
  }, []);

  return (
    <div className="top-0 fixed max-w-xl w-full h-full bg-black opacity-25" onClick={props.onClick} />
  );
}
