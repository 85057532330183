import axios from 'axios';

export function checkNotificationPermission() {
  if (!('PushManager' in window) || Notification.permission !== 'default') {
    return;
  }

  requestNotificationPermission().then(() => {
    if (Notification.permission === 'granted') {
      subscribeToNotifications();
    }
  });
}

function requestNotificationPermission() {
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then((permissionResult) => {
    if (permissionResult !== 'granted') {
      console.error(permissionResult);
    }
  });
}

function subscribeToNotifications() {
  return navigator.serviceWorker.ready.then((registration) => {
    return registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
    });
  }).then((subscription) => {
    axios.post('notifications/subscribe', subscription)
    .catch((error) => {
      console.error(error);
    });
  });
}

// https://gist.github.com/Klerith/80abd742d726dd587f4bd5d6a0ab26b6
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);

  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; i++) {
      outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}
