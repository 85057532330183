import React, {useState, useEffect} from 'react';
import {Link, Navigate, useParams} from 'react-router-dom';
import axios from 'axios';

export default function VerifyEmail(props) {
  const {url} = useParams();

  const [success, setSuccess] = useState(false);
  const [status, setStatus] = useState('Din e-mailadresse bliver bekræftet...');

  const apiUrl = 'verify-email/' + url.replaceAll('-', '/') + window.location.search;
  useEffect(() => {
    axios.get(apiUrl).then((response) => {
      setStatus('Din e-mailadresse blev bekræftet.');
      setTimeout(() => setSuccess(true), 3000);
    }).catch((error) => {
      console.error(error);
      setStatus('Din e-mailadresse kunne ikke bekræftes.');
    });
  }, []);

  if (success) {
    return <Navigate to="/" />;
  }

  return (
    <div className="text-center">
      <h1 className="font-black mt-10 text-7xl w-full">
        SPIGA
        <span className="text-spiga-green">.</span>
      </h1>
      <p className="font-bold text-sm uppercase">A <span className="text-spiga-green">free</span> and <span className="text-spiga-green">social</span> sport prediction game</p>
      <div className="mt-10">
        <p className="text-lg">{status}</p>
        <div className="w-full">
          <Link to="/">
            <button className="border-2 border-gray-300 border-solid font-bold mt-10 p-2 rounded text-spiga-green text-xl uppercase">
              Gå til forsiden
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
