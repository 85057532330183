import React from 'react';
import {Link} from 'react-router-dom';
// import {Adsense} from '@ctrl/react-adsense';
import home from '../img/home.svg';
import tournaments from '../img/tournaments.svg';
import settings from '../img/settings.svg';

export default function NavBar() {
  function isCurrentPage(url) {
    return window.location.pathname === url;
  }

  return (
    <div className="bottom-0 fixed max-w-xl w-full">
      {/* <Adsense client="ca-pub-2152639762525325" format="" slot="5898781326" style={{display: 'block', height: 60, width: '100%'}} /> */}
      <div className="bg-black text-white">
        <div className="flex mx-auto w-2/3">
          <Link className="basis-1/3 flex py-4" to="/">
            <img className={"grow h-6" + (isCurrentPage('/') ? "" : " brightness-50")} src={home} alt="Hjem" />
          </Link>
          <Link className="basis-1/3 flex py-4" to="/tournaments">
            <img className={"grow h-6" + (isCurrentPage('/tournaments') ? "" : " brightness-50")} src={tournaments} alt="Turneringer" />
          </Link>
          <Link className="basis-1/3 flex py-4" to="/settings">
            <img className={"grow h-6" + (isCurrentPage('/settings') ? "" : " brightness-50")} src={settings} alt="Indstillinger" />
          </Link>
        </div>
      </div>
    </div>
  );
}
