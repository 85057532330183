import React from 'react';
import flagAr from '../img/countries/ar.svg';
import flagAu from '../img/countries/au.svg';
import flagBe from '../img/countries/be.svg';
import flagBr from '../img/countries/br.svg';
import flagCa from '../img/countries/ca.svg';
import flagCh from '../img/countries/ch.svg';
import flagCm from '../img/countries/cm.svg';
import flagCr from '../img/countries/cr.svg';
import flagDe from '../img/countries/de.svg';
import flagDk from '../img/countries/dk.svg';
import flagEc from '../img/countries/ec.svg';
import flagEs from '../img/countries/es.svg';
import flagFr from '../img/countries/fr.svg';
import flagGbEng from '../img/countries/gb-eng.svg';
import flagGbWls from '../img/countries/gb-wls.svg';
import flagGh from '../img/countries/gh.svg';
import flagHr from '../img/countries/hr.svg';
import flagIr from '../img/countries/ir.svg';
import flagJp from '../img/countries/jp.svg';
import flagKr from '../img/countries/kr.svg';
import flagMa from '../img/countries/ma.svg';
import flagMx from '../img/countries/mx.svg';
import flagNl from '../img/countries/nl.svg';
import flagPl from '../img/countries/pl.svg';
import flagPt from '../img/countries/pt.svg';
import flagQa from '../img/countries/qa.svg';
import flagRs from '../img/countries/rs.svg';
import flagSa from '../img/countries/sa.svg';
import flagSn from '../img/countries/sn.svg';
import flagTn from '../img/countries/tn.svg';
import flagUs from '../img/countries/us.svg';
import flagUy from '../img/countries/uy.svg';

const CountryFlags = {
  "ar": flagAr,
  "au": flagAu,
  "be": flagBe,
  "br": flagBr,
  "ca": flagCa,
  "ch": flagCh,
  "cm": flagCm,
  "cr": flagCr,
  "de": flagDe,
  "dk": flagDk,
  "ec": flagEc,
  "es": flagEs,
  "fr": flagFr,
  "gb-eng": flagGbEng,
  "gb-wls": flagGbWls,
  "gh": flagGh,
  "hr": flagHr,
  "ir": flagIr,
  "jp": flagJp,
  "kr": flagKr,
  "ma": flagMa,
  "mx": flagMx,
  "nl": flagNl,
  "pl": flagPl,
  "pt": flagPt,
  "qa": flagQa,
  "rs": flagRs,
  "sa": flagSa,
  "sn": flagSn,
  "tn": flagTn,
  "us": flagUs,
  "uy": flagUy,
};

export default function TeamIcon(props) {
  if (props.country) {
    const className = 'inline mb-1 ' + props.className.replace('w-', '');
    const src = CountryFlags[props.country];
    const alt = props.country + ' flag';

    return (
      <img className={className} src={src} alt={alt} />
    );
  }

  const className = 'border-4 inline-block ' + props.className;
  const style1 = {
    backgroundColor: props.color1,
    borderColor: (props.color1 !== '#FFFFFF') ? props.color1 : props.color2,
  };
  const style2 = {
    backgroundColor: props.color2,
    borderColor: (props.color2 !== '#FFFFFF') ? props.color2 : props.color1,
  };

  return (
    <span>
      <span className={className} style={style1}></span>
      <span className={className} style={style2}></span>
    </span>
  );
}
