import React from 'react';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query';
import BetStatus from '../enums/BetStatus';
import BetType from '../enums/BetType';
import TitleBar from '../components/TitleBar';
import NavBar from '../components/NavBar';
import TeamIcon from '../components/TeamIcon';
import Time from '../components/Time';
import coin from '../img/coin.svg';

export default function Bets(props) {
  const BetStatusDescriptions = {
    [BetStatus.Undecided]: '',
    [BetStatus.Loss]: ' Du tabte.',
    [BetStatus.Win]: ' Du vandt!',
  }
  const BetTypeNames = {
    [BetType.Winner1]: 'hjemmeholdet vinder',
    [BetType.WinnerX]: 'uafgjort',
    [BetType.Winner2]: 'udeholdet vinder',
  }

  const betsQuery = useQuery(['bets'], () =>
    axios.get('bets').then((response) => response.data)
  );

  let bets = [];
  if (betsQuery.isSuccess) {
    bets = betsQuery.data.map((bet) => {
      return {
        ...bet,
        type_name: BetTypeNames[bet.type_id],
        odds: (bet.odds / 100).toFixed(2).replace('.', ','),
        status_description: BetStatusDescriptions[bet.status_id],
      };
    });
  }

  return (
    <div>
      <TitleBar user={props.user} setUser={props.setUser} />
      <div className="mb-32 mt-1 mx-3">
        <div className="mt-4">
          <h2 className="font-black text-xl uppercase">Dine spil</h2>
          <div className="flex flex-col items-center mt-2">
          {betsQuery.isLoading ? (
              'Indlæser spil...'
            ) : betsQuery.isError ? (
              'Fejl under indlæsning af spil: ' + betsQuery.error.message
            ) : (
              bets.map((bet) =>
                <div key={bet.id} className="border-2 border-gray-300 border-solid mt-2 first:mt-0 rounded-lg p-2 text-left w-full">
                  <div className="inline-block">
                    <span className="mr-1">{bet.team_home_name}</span>
                    <TeamIcon className="h-4 w-4" color1={bet.team_home_color_1} color2={bet.team_home_color_2} country={bet.team_home_country} />
                  </div>
                  <span className="ml-2.5 mr-2">v</span>
                  <div className="inline-block">
                    <span className="mr-1">{bet.team_away_name}</span>
                    <TeamIcon className="h-4 w-4" color1={bet.team_away_color_1} color2={bet.team_away_color_2} country={bet.team_away_country} />
                  </div>
                  <span className="float-right">
                    <Time time={bet.fixture_time} />
                  </span>
                  <p className="inline-block">
                    {bet.coins}
                    <img className="h-5 inline mb-1 mx-1.5" src={coin} alt="SPIGA coin" />
                    på "{bet.type_name}" til odds {bet.odds} i turneringen {bet.tournament_name}.{bet.status_description}
                    <span className="float-right text-sm">
                      Spillet d. <Time time={bet.created_at} />
                    </span>
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <NavBar />
    </div>
  );
}
