import React, {useState} from 'react';
import {Navigate} from 'react-router-dom';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query';
import TitleBar from '../components/TitleBar';
import NavBar from '../components/NavBar';
import Search from '../components/Search';

export default function TournamentsCreate(props) {
  const durations = [7, 14, 30, 45, 60];
  const defaultDuration = 30;

  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [tournamentId, setTournamentId] = useState(null);
  const [invitedUsers, setInvitedUsers] = useState([]);

  const leaguesQuery = useQuery(['leagues'], () =>
    axios.get('leagues').then((response) => response.data)
  );

  function handleSearchClick(type, userId, username) {
    if (type !== 'user' || invitedUsers.some((user) => user.id === userId)) {
      return;
    }

    if (userId === props.user.data?.id) {
      setError('Du kan ikke invitere dig selv.');
      return;
    }

    if (invitedUsers.length >= 49) {
      setError('En turnering kan kun indeholde 50 brugere.');
      return;
    }

    setInvitedUsers([...invitedUsers, {
      id: userId,
      username: username,
    }]);
  }

  function handleInvitedUserClick(event) {
    const userId = parseInt(event.target.getAttribute('data'));
    setInvitedUsers(invitedUsers.filter((user) => user.id !== userId));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!leaguesQuery.isSuccess) {
      setError('Ligaer er ikke blevet indlæst.');
      return;
    }

    if (submitting) {
      return;
    }
    setSubmitting(true);

    const userIds = invitedUsers.map((user) => user.id);
    axios.post('tournaments', {
      name: e.target.name.value,
      league_id: e.target.league_id.value,
      duration: e.target.duration.value,
      user_ids: userIds,
    }).then((response) => response.data)
    .then((data) => {
      setTournamentId(data.id);
      setSuccess(true);
    }).catch(error => {
      console.error(error);
      setError(error.response.data.message);
      setSubmitting(false);
    });
  }

  if (success) {
    return <Navigate to={'/tournaments/' + tournamentId} />;
  }

  return (
    <div>
      <TitleBar user={props.user} setUser={props.setUser} />
      <div className="mb-32 mt-1 mx-3">
        <h2 className="font-black text-center text-xl uppercase">Opret turnering</h2>
        <form className="flex flex-col items-center w-full" onSubmit={handleSubmit}>
          <label htmlFor="name" className="font-bold mt-2">Turneringens navn</label>
          <input name="name" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" type="text" id="name" autoComplete="off" required />
          <label htmlFor="league_id" className="font-bold mt-3">Liga</label>
          <select name="league_id" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" id="league_id" required>
            {leaguesQuery.isLoading ? (
                <option>Indlæser ligaer...</option>
              ) : leaguesQuery.isError ? (
                <option>Fejl under indlæsning af ligaer: {leaguesQuery.error.message}</option>
              ) : (
                leaguesQuery.data.map((league) => (
                  <option key={league.league_id} value={league.league_id}>{league.name}</option>
                ))
              )}
          </select>
          <label htmlFor="duration" className="font-bold mt-3">Varighed</label>
          <select name="duration" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" id="duration" defaultValue={defaultDuration} required>
            {durations.map((days) =>
              <option key={days} value={days}>{days} dage</option>
              )}
          </select>
          <label htmlFor="invite" className="font-bold mt-3">Invitér dine venner</label>
          <div className="w-2/3" id="invite">
            <Search types={['users']} placeholder="Find bruger" onResultClick={handleSearchClick} />
            <div>
              {invitedUsers.map((user, index) =>
                <span key={user.id}>
                  {index > 0 ? ', ' : ''}
                  <button type="button" data={user.id} onClick={handleInvitedUserClick}>{user.username}</button>
                </span>
              )}
            </div>
          </div>
          <button type="submit" className="border-2 border-gray-300 border-solid font-bold mt-5 p-2 rounded text-xl uppercase">
            Opret nu
          </button>
          <p className="mt-3 text-rose-600">{error}</p>
        </form>
      </div>
      <NavBar />
    </div>
  );
}
