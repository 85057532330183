import React, {useState} from 'react';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query';
import BetType from '../enums/BetType';
import Overlay from './Overlay';
import TeamIcon from './TeamIcon';
import Time from './Time';
import coin from '../img/coin.svg';

export default function BetPopup(props) {
  const inTournament = typeof props.tournament !== 'undefined';
  const oddsStr = (props.state.odds / 100).toFixed(2).replace('.', ',');
  const coinAmounts = [50, 100, 200];
  const closestCoinAmount = Math.max(...coinAmounts.filter((coins) => coins <= props.user.data?.coins));
  const defaultCoins = Math.max(coinAmounts[0], Math.min(coinAmounts[coinAmounts.length - 1], closestCoinAmount));
  const BetTypeNames = {
    [BetType.Winner1]: 'Hjemmeholdet vinder',
    [BetType.WinnerX]: 'Uafgjort',
    [BetType.Winner2]: 'Udeholdet vinder',
  }
  const matchStarted = new Date(props.state.time) < new Date();

  const [selectedTournament, setSelectedTournament] = useState(null);
  const [selectedCoins, setSelectedCoins] = useState(defaultCoins);
  const [betting, setBetting] = useState(false);
  const [status, setStatus] = useState('');
  const [hideTimer, setHideTimer] = useState(null);

  const potentialWinnings = Math.floor(selectedCoins * props.state.odds / 100);
  const selectedInputCoins = !coinAmounts.includes(selectedCoins);

  const tournamentsQuery = useQuery(['tournaments'], () =>
    axios.get('tournaments').then((response) => response.data)
  );

  function tournamentFiltered(tournament) {
    return tournament.league_id === props.state.league &&
      (new Date(tournament.end_at)).getTime() > (new Date()).getTime();
  }

  function hidePopup(delay = 0) {
    if (hideTimer !== null) {
      clearTimeout(hideTimer);
    }

    if (delay <= 0) {
      setHideTimer(null);
      props.setState(null);
    } else {
      setHideTimer(setTimeout(() => {
        props.setState(null);
      }, delay));
    }
  }

  function handleOverlayClick(event) {
    hidePopup();
  }

  function selectTournament(e) {
    setSelectedTournament(e.target.value);
  }

  function handleInputClick(event) {
    if (event.target.value) {
      setSelectedCoins(event.target.value);
    }
  }

  function handleInputChange(event) {
    if (event.target.value) {
      event.target.value = Math.max(1, Math.floor(event.target.value));
      setSelectedCoins(event.target.value);
    } else {
      event.target.value = '';
      setSelectedCoins(defaultCoins);
    }
  }

  function handleInputKey(event) {
    if (event.key === '.' || event.key === ',' || event.key === '+' ||
      event.key === '-' || event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  }

  function handleBet() {
    if (selectedCoins > props.user.data?.coins) {
      setStatus(
        <p>
          Du har ikke tilstrækkelige
          <img className="h-5 inline mb-1 ml-1.5" src={coin} alt="SPIGA coin" />
          .
        </p>
      );
      return;
    }

    if (!inTournament) {
      if (!tournamentsQuery.isSuccess) {
        setStatus(<p>Turneringer er ikke blevet indlæst.</p>);
        return;
      } else if (selectedTournament === null) {
        setStatus(<p>Du har ikke valgt en turnering.</p>);
        return;
      }
    }

    setBetting(true);

    axios.post('bet', {
      tournament_id: inTournament ? props.tournament : selectedTournament,
      fixture_id: props.state.fixture,
      type_id: props.state.type,
      coins: selectedCoins,
    }).then(() => {
      setStatus(
        <p>
          Du har spillet {selectedCoins}
          <img className="h-5 inline mb-1 mx-1.5" src={coin} alt="SPIGA coin" />
          på denne kamp.
        </p>);
      hidePopup(3000);
    }).catch((error) => {
      console.error(error);
      setStatus(
        <p>
          Fejl under forsøg på at spille på denne kamp.
        </p>);
      setBetting(false);
    });
  }

  return (
    <div>
      <Overlay onClick={handleOverlayClick} />
      <div className="bg-white bottom-0 mx-auto fixed flex flex-col max-w-xl py-8 rounded-t-2xl w-full z-10 px-8">
        <div className="flex flex-row font-bold mb-2 text-lg">
          {status}
        </div>
        <div className="flex flex-row font-black text-xl">
          <div className="basis-1/2 flex flex-col">
            <h3 className="uppercase">Saldo</h3>
          </div>
          <div className="basis-1/2 flex flex-col items-end">
            <h3>
              {props.user.data?.coins}
              <img className="h-5 inline mb-1 ml-1.5" src={coin} alt="SPIGA coin" />
            </h3>
          </div>
        </div>
        <hr className="bg-gray-300 h-0.5 mt-3" />
        <div className="flex flex-row mt-3">
          <div className="basis-1/2 flex flex-col">
            <div className="font-bold">
              <div className="inline-block">
                <span className="mr-1">{props.state.teamHome}</span>
                <TeamIcon className="h-4 w-4" color1={props.state.teamHomeColor1} color2={props.state.teamHomeColor2} country={props.state.teamHomeCountry} />
              </div>
              <span className="ml-2.5 mr-2">v</span>
              <div className="inline-block">
                <span className="mr-1">{props.state.teamAway}</span>
                <TeamIcon className="h-4 w-4" color1={props.state.teamAwayColor1} color2={props.state.teamAwayColor2} country={props.state.teamAwayCountry} />
              </div>
            </div>
          </div>
          <div className="basis-1/2 flex flex-col items-end">
            <Time time={props.state.time} />
          </div>
        </div>
        <div className="flex flex-row mt-1">
          <div className="basis-1/2 flex flex-col">
            <p>{BetTypeNames[props.state.type]}</p>
          </div>
          <div className="basis-1/2 flex flex-col items-end">
            <p className="font-bold">{oddsStr}</p>
          </div>
        </div>
        {!matchStarted ? (
          <>
            {!inTournament ?
              <div className="mt-1">
                Vælg turnering:
                <select name="tournament_id" className="border-2 border-gray-300 border-solid ml-2 p-1 rounded" id="tournament_id" required disabled={betting} onChange={selectTournament}>
                  <option></option>
                  {tournamentsQuery.isLoading ? (
                    <option>Indlæser turneringer...</option>
                  ) : tournamentsQuery.isError ? (
                    <option>Fejl under indlæsning af turneringer: {tournamentsQuery.error.message}</option>
                  ) : (
                    tournamentsQuery.data.map((tournament) => (
                      tournamentFiltered(tournament) ? (
                        <option key={tournament.id} value={tournament.id}>{tournament.name}</option>
                      ) : ''
                    ))
                  )}
                </select>
              </div>
            : ''}
            <div className="flex flex-row mt-3">
              <div className="basis-2/3 flex flex-col">
                <div className="border-2 border-gray-300 border-solid font-black mr-3 p-2 rounded-md text-center">
                  {selectedCoins}
                  <img className="h-4 inline mb-1 ml-1" src={coin} alt="SPIGA coin" />
                  <span className="mx-3">&rarr;</span>
                  {potentialWinnings}
                  <img className="h-4 inline mb-1 ml-1" src={coin} alt="SPIGA coin" />
                </div>
              </div>
              <div className="basis-1/3 flex flex-col">
                <button className="border-2 border-spiga-green border-solid bg-spiga-green font-black p-2 rounded-md text-white uppercase" disabled={betting} onClick={handleBet}>
                  Spil nu
                </button>
              </div>
            </div>
            <div className="flex flex-row mt-3">
                {coinAmounts.map((coins) =>
                  <div key={coins} className="basis-1/6 flex flex-col grow mr-3 last:mr-0">
                    <button
                      className={"font-bold p-2 rounded-md" + (selectedCoins === coins ? " bg-spiga-green text-white" : " bg-gray-100")}
                      disabled={betting}
                      onClick={() => setSelectedCoins(coins)}
                    >
                      {coins}
                    </button>
                  </div>
                )}
                <input
                  name="username"
                  className={'basis-1/3 border-2 border-gray-300 border-solid font-bold p-1 rounded-md text-center w-0' + (selectedInputCoins ? ' bg-spiga-green text-white' : ' bg-gray-100')}
                  type="number"
                  autoComplete="off"
                  disabled={betting}
                  onClick={handleInputClick}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKey}
                />
            </div>
          </>
        ) : (
          <div className="flex flex-row font-bold mt-2 text-lg">
            Det er endnu ikke muligt at spille på live kampe.
          </div>
        )}
      </div>
    </div>
  );
}
