import React from 'react';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query';
import TournamentItem from './TournamentItem';

export default function TournamentItems(props) {
  const tournamentsQuery = useQuery(['tournaments'], () =>
    axios.get('tournaments').then((response) => response.data)
  );

  return (
    <>
      <TournamentItem title="Opret turnering" text="Invitér venner" color="#00E5AE" link="/tournaments/create" inline={props.inline} />
      {tournamentsQuery.isLoading ? (
          <p className="inline ml-3 mt-3">Indlæser turneringer...</p>
        ) : tournamentsQuery.isError ? (
          <p className="inline ml-3 mt-3">Fejl under indlæsning af turneringer: {tournamentsQuery.error.message}</p>
        ) : (
          tournamentsQuery.data.map((tournament) =>
            <TournamentItem
              key={tournament.id}
              title={tournament.name}
              text="Se stillingen"
              color={tournament.pivot.color}
              link={'/tournaments/' + tournament.id}
              inline={props.inline}
            />
          )
        )}
    </>
  );
}
