import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query';
import profile from '../img/profile.svg';

function getSrc(userId) {
  return userId ? (process.env.REACT_APP_API_URL + '/img/user/' + userId) : profile;
}

export default function UserImg(props) {
  const [src, setSrc] = useState(getSrc(props.userId));

  const imgQuery = useQuery([src], () => {
    if (src === profile) {
      return profile;
    }

    return axios.get(src, {
      responseType: 'blob'
    }).then((response) => new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(response.data);
    }));
  }, {
    initialData: profile,
  });

  useEffect(() => {
    setSrc(getSrc(props.userId));
  }, [props.userId]);

  return (
    <>
      <img className={props.className} src={props.src ?? imgQuery.data} alt="Profilbillede" />
    </>
  );
}
