import React, {useState} from 'react';
import {Navigate} from 'react-router-dom';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query';
import TitleBar from '../components/TitleBar';
import NavBar from '../components/NavBar';
import Time from '../components/Time';

export default function Notifications(props) {
  const [redirect, setRedirect] = useState(null);

  const notificationsQuery = useQuery(['notifications'], () =>
    axios.get('notifications').then((response) => response.data)
  );

  function handleClick(event) {
    setRedirect(event.currentTarget.getAttribute('data'));
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <div>
      <TitleBar user={props.user} setUser={props.setUser} />
      <div className="mb-32 mt-1 mx-3">
        <div className="mt-4">
          <h2 className="font-black text-xl uppercase">Notifikationer</h2>
          <div className="flex flex-col items-center mt-2">
          {notificationsQuery.isLoading ? (
              'Indlæser notifikationer...'
            ) : notificationsQuery.isError ? (
              'Fejl under indlæsning af notifikationer: ' + notificationsQuery.error.message
            ) : (
              notificationsQuery.data.map((notification) =>
                <button
                  key={notification.id}
                  className="border-2 border-gray-300 border-solid mt-2 first:mt-0 rounded-lg p-2 text-left w-full"
                  data={notification.data.data}
                  onClick={handleClick}
                  disabled={!notification.data.data}
                >
                  <p>
                    {notification.data.text}
                    <span className="float-right text-sm">
                      <Time time={notification.created_at} />
                    </span>
                  </p>
                </button>
              )
            )}
          </div>
        </div>
      </div>
      <NavBar />
    </div>
  );
}
