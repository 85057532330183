import React from 'react';
import axios from 'axios';

export default function LogoutButton(props) {
  function handleClick() {
    axios.post('logout').then(() => {
      props.setUser({loggedIn: false, data: null});
    }).catch(error => {
      console.log(error);
    });
  }

  return (
    <button className={props.className} onClick={handleClick}>
      Log ud
    </button>
  );
}
