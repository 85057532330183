import React from 'react';
import {Link} from 'react-router-dom';
import arrow from '../img/arrow.svg';

export default function TournamentItem(props) {
  return (
    <Link
      className={"h-28 rounded-xl text-white" + (props.inline === "true" ?
                 " inline-block ml-4 first:ml-0 snap-start w-56" :
                 " mt-4 first:mt-0 w-full")}
      style={{backgroundColor: props.color}}
      to={props.link}
    >
      <div className="pl-6 pt-6">
        <img className="h-6 inline mb-0.5" src={arrow} alt="Pil" />
        <span className="ml-2">{props.text}</span>
      </div>
      <h3 className="font-bold text-lg uppercase pl-6 pt-4">{props.title}</h3>
    </Link>
  );
}
