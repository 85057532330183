import React from 'react';
import TeamIcon from './TeamIcon';
import Time from './Time';
import BetType from '../enums/BetType';

export default function MatchItem(props) {
  const live = props.scoreHome !== null && props.scoreAway !== null;
  const score = live ? (
      props.scoreHome + ":" + props.scoreAway
    ) : (
      <>-</>
    );
  const odds1Str = (props.odds1 / 100).toFixed(2).replace('.', ',');
  const oddsXStr = (props.oddsX / 100).toFixed(2).replace('.', ',');
  const odds2Str = (props.odds2 / 100).toFixed(2).replace('.', ',');

  function handleBet(type) {
    const odds = {
      [BetType.Winner1]: props.odds1,
      [BetType.WinnerX]: props.oddsX,
      [BetType.Winner2]: props.odds2,
    }[type];

    props.setBetPopupState({
      fixture: props.fixture,
      type: type,
      league: props.league,
      leagueName: props.leagueName,
      teamHome: props.teamHome,
      teamHomeColor1: props.teamHomeColor1,
      teamHomeColor2: props.teamHomeColor2,
      teamHomeCountry: props.teamHomeCountry,
      teamAway: props.teamAway,
      teamAwayColor1: props.teamAwayColor1,
      teamAwayColor2: props.teamAwayColor2,
      teamAwayCountry: props.teamAwayCountry,
      odds: odds,
      time: props.time,
    });
  }

  return (
    <div className="border-2 border-gray-300 border-solid flex flex-col mt-3 first:mt-0 p-3 rounded-lg text-center w-full">
      <div className="flex flex-row grow">
        <div className="basis-1/3 flex flex-col grow items-center justify-center">
          <p className="font-bold leading-none mb-1">{props.teamHome}</p>
          <TeamIcon className="h-8 mb-6 mt-1 w-8" color1={props.teamHomeColor1} color2={props.teamHomeColor2} country={props.teamHomeCountry} />
        </div>
        <div className="basis-1/3 flex flex-col grow items-center justify-center ml-3">
          <p className="flex grow items-center text-sm">{props.leagueName}</p>
          <p className="flex font-black grow items-center text-3xl">{score}</p>
          <div className="flex grow items-center text-sm">
            {live ?
              <span className="bg-spiga-green h-3 mr-1 rounded-md w-3" />
            : ''}
            <Time time={props.time} inline={false} />
          </div>
        </div>
        <div className="basis-1/3 flex flex-col grow items-center justify-center ml-3">
          <p className="font-bold leading-none mb-1">{props.teamAway}</p>
          <TeamIcon className="h-8 mb-6 mt-1 w-8" color1={props.teamAwayColor1} color2={props.teamAwayColor2} country={props.teamAwayCountry} />
        </div>
      </div>
      <div className="flex flex-row grow">
        <button className="basis-1/3 bg-gray-200 flex grow h-10 items-center justify-center rounded-md" onClick={() => handleBet(BetType.Winner1)}>
          <p className="font-bold">{odds1Str}</p>
        </button>
        <button className="basis-1/3 bg-gray-200 flex grow h-10 ml-3 items-center justify-center rounded-md" onClick={() => handleBet(BetType.WinnerX)}>
          <p className="font-bold">{oddsXStr}</p>
        </button>
        <button className="basis-1/3 bg-gray-200 flex grow h-10 ml-3 items-center justify-center rounded-md" onClick={() => handleBet(BetType.Winner2)}>
          <p className="font-bold">{odds2Str}</p>
        </button>
      </div>
    </div>
  );
}
