import React, {useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query';
import TournamentUserStatus from '../enums/TournamentUserStatus';
import NavBar from '../components/NavBar';
import Search from '../components/Search';
import coin from '../img/coin.svg';

export default function Tournament(props) {
  const {id} = useParams();

  const [status, setStatus] = useState(null);

  const tournamentUrl = 'tournaments/' + id;
  const tournamentQuery = useQuery([tournamentUrl], () =>
    axios.get(tournamentUrl).then((response) => response.data)
  );

  const endAt = (new Date(tournamentQuery.data?.end_at)).getTime();
  const now = (new Date()).getTime();
  const ended = endAt < now;
  const daysLeft = Math.max(0, Math.floor(((endAt - now) / (1000 * 60 * 60 * 24))));

  function handleJoin(event) {
    axios.post('tournaments/join', {
      tournament_id: id,
    }).then(() => {
      tournamentQuery.refetch();
    });
  }

  function handleSearchClick(type, userId, username) {
    if (type !== 'user') {
      return;
    }

    if (userId === props.user.data?.id) {
      setStatus('Du kan ikke invitere dig selv.');
      return;
    }

    axios.post('/tournaments/invite', {
      tournament_id: id,
      user_id: userId,
    }).then(() => {
      setStatus(username + ' blev inviteret.');
    }).catch((error) => {
      console.error(error);
      setStatus(error.response.data.message);
    });
  }

  return (
    <div>
      <div className="bg-white pb-2 pt-3 sticky text-center top-0">
        <h1 className="font-black text-3xl uppercase w-full">
          {tournamentQuery.isSuccess ? tournamentQuery.data.name : <>&nbsp;</>}
        </h1>
      </div>
      <div className="mb-32 mt-1 mx-3">
        <div className="flex justify-center">
          <div className="basis-4/5">
            <Search types={['users']} placeholder="Invitér bruger" onResultClick={handleSearchClick} />
          </div>
        </div>
        <div>{status}</div>
        <div className="mt-4">
          {tournamentQuery.isLoading ? (
              'Indlæser turnering...'
            ) : tournamentQuery.isError ? (
              'Fejl under indlæsning af turnering: ' + tournamentQuery.error.message
            ) : (
              tournamentQuery.data.pivot.status_id === TournamentUserStatus.Invited ? (
                <div className="flex flex-col items-center">
                  <div className="font-bold text-lg">Du er blevet inviteret til at deltage i denne turnering.</div>
                  <button className="border-2 border-spiga-green border-solid bg-spiga-green font-black mt-3 p-2 rounded-md text-lg text-white uppercase" onClick={handleJoin}>
                    Acceptér invitation
                  </button>
                </div>
              ) : (
                <>
                  <h3 className="font-bold text-lg uppercase">Liga: {tournamentQuery?.data.league_name}</h3>
                  <h3 className="font-bold text-lg uppercase">
                    Saldo: {props.user.data?.coins}
                    <img className="h-5 inline mb-1 ml-1.5" src={coin} alt="SPIGA coin" />
                  </h3>
                  {!ended ? (
                    <Link to={'/bet/' + id}>
                      <button className="border-2 border-spiga-green border-solid bg-spiga-green font-black mt-3 p-2 rounded-md text-white uppercase w-1/4">
                        Spil nu
                      </button>
                    </Link>
                  ) : ''}
                  <div className="border-2 border-gray-300 border-solid flex flex-col font-bold mt-4 rounded-lg px-3 py-2 text-center uppercase">
                    <div className="border-2 border-white border-solid flex mt-2 rounded-lg p-1">
                      <p className="basis-1/2 text-left text-spiga-green">
                        {!ended ? (
                          <>{daysLeft} dag{daysLeft === 1 ? '' : 'e'} tilbage</>
                        ) : (
                          <>Turneringen er slut</>
                        )}
                      </p>
                      <p className="basis-1/4">Antal spil</p>
                      <p className="basis-1/4">Saldo</p>
                    </div>
                    {tournamentQuery.data.users.map((user, index) =>
                      <div key={index} className="border-2 border-gray-300 border-solid flex mt-2 rounded-lg p-1">
                        <p className="basis-[10%]">{index + 1}.</p>
                        <Link className="basis-2/5 text-left" to={'/users/' + user.id}>{user.name}</Link>
                        <p className="basis-1/4">{user.total_bets}</p>
                        <p className="basis-1/4">
                          {user.coins}
                          <img className="h-5 inline mb-1 ml-1.5" src={coin} alt="SPIGA coin" />
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )
            )}
        </div>
      </div>
      <NavBar />
    </div>
  );
}
