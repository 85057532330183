import React, {useEffect} from 'react';
import {Routes, useLocation} from 'react-router-dom';

export default function MatchItem(props) {
  const sendPageview = props.sendPageview;

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    sendPageview();
  }, [location, sendPageview]);

  return (
    <Routes>
      {props.children}
    </Routes>
  );
}
