import React from 'react';

const MonthNames = [
  'jan.',
  'feb.',
  'mar.',
  'apr.',
  'maj',
  'jun.',
  'jul.',
  'aug.',
  'sep.',
  'okt.',
  'nov.',
  'dec.',
];

export default function Time(props) {
  const inline = props.inline ?? true;

  const dateObject = new Date(props.time);
  const date =
    dateObject.getDate() + '. ' +
    MonthNames[dateObject.getMonth()] + ' ' +
    dateObject.getFullYear();
  const time =
    dateObject.getHours().toString().padStart(2, '0') + ':' +
    dateObject.getMinutes().toString().padStart(2, '0');

  if (inline) {
    return (
      <>{date} kl. {time}</>
    );
  } else {
    return (
      <div>
        <div className="text-center">{date}</div>
        <div className="text-center">{time}</div>
      </div>
    );
  }
}
