import React from 'react';
import {Link} from 'react-router-dom';
import TitleBar from '../components/TitleBar';
import NavBar from '../components/NavBar';
import LogoutButton from '../components/LogoutButton';

export default function Settings(props) {
  return (
    <div>
      <TitleBar user={props.user} setUser={props.setUser} />
      <div className="mb-32 mt-1 mx-3">
        <div className="mt-4">
          <h2 className="font-black text-xl uppercase">Indstillinger</h2>
          <div className="flex flex-col items-center mt-2 text-center">
            <Link className="border-2 border-gray-300 border-solid font-bold p-2 rounded text-xl uppercase w-full" to="/user">
              Profil
            </Link>
            <Link className="border-2 border-gray-300 border-solid font-bold mt-5 p-2 rounded text-xl uppercase w-full" to="/notifications">
              Notifikationer
            </Link>
            <Link className="border-2 border-gray-300 border-solid font-bold mt-5 p-2 rounded text-xl uppercase w-full" to="/bets">
              Dine spil
            </Link>
            <LogoutButton className="border-2 border-gray-300 border-solid font-bold mt-5 p-2 rounded text-xl uppercase w-full" setUser={props.setUser} />
          </div>
        </div>
      </div>
      <NavBar />
    </div>
  );
}
