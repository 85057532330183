import React, {useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query';
import TitleBar from '../components/TitleBar';
import NavBar from '../components/NavBar';
import UserImg from '../components/UserImg';
import LogoutButton from '../components/LogoutButton';
import coin from '../img/coin.svg';

export default function User(props) {
  const {id} = useParams();
  const ownProfile = typeof id === 'undefined' || parseInt(id) === props.user.data?.id;

  const [editing, setEditing] = useState(false);
  const [editingImg, setEditingImg] = useState(false);
  const [editingPassword, setEditingPassword] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);

  const imgFileRef = useRef();

  const userQueryKey = 'user' + (!ownProfile ? ('s/' + id) : '');
  const userQuery = useQuery([userQueryKey], () => {
    if (ownProfile) {
      return props.user.data;
    }

    return axios.get('users/' + id).then((response) => response.data);
  });

  useEffect(() => {
    if (ownProfile) {
      userQuery.refetch();
    }
  }, [props.user]);

  function promptImg(event) {
    imgFileRef.current.click();
  }

  function selectedImg(event) {
    if (!event.target.files) {
      return;
    }

    setEditingImg(true);

    if (FileReader) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImgSrc(fileReader.result);
      };
      fileReader.readAsDataURL(event.target.files[0]);
    }
  }

  function saveProfile(event) {
    event.preventDefault();

    if (saving) {
      return;
    }
    setSaving(true);

    axios.post('user', {
      name: event.target.name.value,
      email: event.target.email.value,
      current_password: event.target.current_password?.value,
      password: event.target.password?.value,
      password_confirmation: event.target.password_confirmation?.value,
    }).then(() => {
      if (!editingImg) {
        stopEditing();
      }
    }).catch(error => {
      console.error(error);
      setError(error.response.data.message);
      if (!editingImg) {
        setSaving(false);
      }
    });

    if (editingImg) {
      const imgFormData = new FormData();
      imgFormData.append('img', event.target.img.files[0]);
      axios.post('img/user', imgFormData, {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(() => {
        // This will stop editing even if the primary request above failed.
        stopEditing();
      }).catch(error => {
        console.error(error);
        setError(error.response.data.message);
        setSaving(false);
      });
    }
  }

  function stopEditing() {
    setEditing(false);
    setEditingImg(false);
    setEditingPassword(false);
    setSaving(false);
    setError(null);
    setImgSrc(null);
  }

  return (
    <div>
      <TitleBar user={props.user} setUser={props.setUser} />
      <div className="mb-32 mt-1 mx-3">
        {!editing ? (
          <div className="flex flex-col items-center mt-4">
            <UserImg className="h-32 rounded" userId={userQuery.data?.id} />
            <h2 className="font-black mt-3 text-xl">{userQuery.data?.name} &mdash; @{userQuery.data?.username}</h2>
            <div className="font-bold mt-3 text-lg">
              Saldo: {userQuery.data?.coins}
              <img className="h-6 inline mb-1 ml-1.5" src={coin} alt="SPIGA coin" />
            </div>
            {ownProfile ? (
              <>
                <button className="border-2 border-gray-300 border-solid font-bold mt-5 p-2 rounded text-xl uppercase" onClick={() => {setEditing(true)}}>
                  Rediger profil
                </button>
                <LogoutButton className="border-2 border-gray-300 border-solid font-bold mt-5 p-2 rounded text-xl uppercase" setUser={props.setUser} />
              </>
            ) : ''}
          </div>
        ) : (
          <form className="flex flex-col items-center mt-4 w-full" onSubmit={saveProfile}>
            <button type="button" onClick={promptImg}>
              <UserImg className="h-32 rounded" userId={props.user.data?.id} src={imgSrc} />
            </button>
            <input ref={imgFileRef} name="img" className="absolute top-[-100px]" type="file" id="img" accept="image/*" onChange={selectedImg} />
            <label htmlFor="name" className="block font-bold mt-3">Navn</label>
            <input name="name" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" type="text" id="name" autoComplete="name" required defaultValue={props.user.data?.name} />
            <label htmlFor="email" className="block font-bold mt-3">E-mail</label>
            <input name="email" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" type="text" id="email" autoComplete="email" required defaultValue={props.user.data?.email} />
            {editingPassword ? (
              <>
                <label htmlFor="current_password" className="block font-bold mt-3">Nuværende adgangskode</label>
                <input name="current_password" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" type="password" id="current_password" autoComplete="current-password" required />
                <label htmlFor="password" className="block font-bold mt-3">Ny adgangskode</label>
                <input name="password" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" type="password" id="password" autoComplete="new-password" required />
                <label htmlFor="password_confirmation" className="block font-bold mt-3">Bekræft ny adgangskode</label>
                <input name="password_confirmation" className="border-2 border-gray-300 border-solid p-1 rounded w-2/3" type="password" id="password_confirmation" autoComplete="new-password" required />
              </>
            ) : ''}
            <button type="submit" className="border-2 border-gray-300 border-solid font-bold mt-5 p-2 rounded text-xl uppercase">
              Gem profil
            </button>
            {!editingPassword ? (
              <button type="button" className="border-2 border-gray-300 border-solid font-bold mt-5 p-2 rounded text-xl uppercase" onClick={() => {setEditingPassword(true)}}>
                Skift adgangskode
              </button>
            ) : ''}
            <p className="mt-3 text-rose-600">{error}</p>
          </form>
        )}
      </div>
      <NavBar />
    </div>
  );
}
