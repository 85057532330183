import React, {useState} from 'react';
import {Navigate} from 'react-router-dom';
import Overlay from '../components/Overlay';

export default function AgePopup(props) {
  const [ageDenied, setAgeDenied] = useState(false);

  function handleClick(ageConfirmed) {
    if (ageConfirmed) {
      props.setState(false);
    } else {
      setAgeDenied(true);
    }
  }

  if (ageDenied) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Overlay />
      <div className="bg-white fixed max-w-xl py-8 rounded-t-2xl top-1/2 -translate-y-1/2 w-full z-10">
        <p className="font-bold text-lg">Er du over 18 år?</p>
        <div className="flex justify-center mt-3">
          <button className="basis-1/4 bg-gray-100 font-bold p-2 rounded-md" onClick={() => handleClick(false)}>
            Nej
          </button>
          <button className="basis-1/4 bg-spiga-green font-bold ml-4 p-2 rounded-md text-white" onClick={() => handleClick(true)}>
            Ja
          </button>
        </div>
      </div>
    </div>
  );
}
