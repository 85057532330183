import React, {useState, useRef} from 'react';
import {Navigate} from 'react-router-dom';
import axios from 'axios';
import {useQuery} from '@tanstack/react-query';

export default function Search(props) {
  const maxResults = 10;

  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState('');
  const [queryTimer, setQueryTimer] = useState(null);
  const [redirect, setRedirect] = useState(null);

  const inputRef = useRef();

  const searchQuery = useQuery(['search-' + query], () => {
    if (query === '') {
      return false;
    }

    return axios.post('search', {
      types: props.types,
      query: query,
    }).then((response) => response.data)
    .then((data) => {
      let results = [];
      for (const type of props.types) {
        let result = [];
        if (type === 'leagues') {
          result = data.leagues.map((league) => {return {
            data: 'league-' + league.league_id,
            text: league.name,
          }});
        } else if (type === 'teams') {
          result = data.teams.map((team) => {return {
            data: 'team-' + team.team_id,
            text: team.name,
          }});
        } else if (type === 'tournaments') {
          result = data.tournaments.map((tournament) => {return {
            data: 'tournament-' + tournament.id,
            text: tournament.name,
          }});
        } else if (type === 'users') {
          result = data.users.map((user) => {return {
            data: 'user-' + user.id,
            text: user.username,
          }});
        }

        results.push(...result);
        if (results.length >= maxResults) {
          break;
        }
      }

      return results.slice(0, maxResults);
    });
  });

  function setInput(event) {
    const value = event.target.value.trim();
    if (value === '') {
      stopSearching(true);
      return;
    }
    setSearching(true);

    // Update the input query at most every 500 milliseconds.
    if (queryTimer !== null) {
      clearTimeout(queryTimer);
    }
    setQueryTimer(setTimeout(() => {
      setQuery(value);
    }, 500));
  }

  function handleFocus(event) {
    if (query) {
      setSearching(true);
    }
  }

  function handleFocusLost(event) {
    setTimeout(stopSearching, 100);
  }

  function handleKeyDown(event) {
    if (event.key === 'Escape') {
      handleFocusLost();
    }
  }

  function handleClick(event) {
    event.preventDefault();

    const [type, idStr] = event.target.getAttribute('data').split('-');
    const id = parseInt(idStr);
    const text = event.target.getAttribute('text');
    if (props.onResultClick) {
      props.onResultClick(type, id, text);
      return;
    }

    if (type === 'tournament') {
      setRedirect('/tournaments/' + id);
    } else if (type === 'user') {
      setRedirect('/users/' + id);
    }
  }

  function stopSearching(resetQuery = false) {
    setSearching(false);
    if (resetQuery) {
      setQuery('');
      inputRef.current.value = '';
    }
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <div className="flex flex-col items-center" onFocus={handleFocus} onBlur={handleFocusLost}>
      <input
        ref={inputRef}
        className="border-2 border-gray-300 border-solid rounded p-1 w-full"
        type="text"
        placeholder={props.placeholder}
        onChange={setInput}
        onKeyDown={handleKeyDown}
      />
      {searching ?
        <div className="relative w-full">
          <div className="absolute border-2 border-gray-300 border-solid rounded p-1 top-2 w-full bg-white">
            {searchQuery.isLoading || searchQuery.data === false ? (
              'Indlæser resultater...'
            ) : searchQuery.isError ? (
              'Fejl under indlæsning af resultater: ' + searchQuery.error.message
            ) : (
              searchQuery.data.length > 0 ? (
                searchQuery.data.map((result) => (
                  <div key={result.data}>
                    <button data={result.data} text={result.text} onClick={handleClick}>
                      {result.text}
                    </button>
                  </div>
                ))
              ) : (
                <div>Ingen resultater</div>
              )
            )}
          </div>
        </div>
      : ''}
    </div>
  );
}
