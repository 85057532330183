import React, {useState} from 'react';
import TitleBar from '../components/TitleBar';
import NavBar from '../components/NavBar';
import MatchItems from '../components/MatchItems';
import TournamentItems from '../components/TournamentItems';
import BetPopup from '../components/BetPopup';
import Search from '../components/Search';

export default function Home(props) {
  const [betPopupState, setBetPopupState] = useState(null);

  return (
    <div>
      <TitleBar user={props.user} setUser={props.setUser} />
      <div className="mb-32 mt-1 mx-3">
        <div className="flex justify-center">
          <div className="basis-4/5">
            <Search types={['users', 'tournaments']} placeholder="Find bruger eller turnering" />
          </div>
        </div>
        <div className="mt-4">
          <h2 className="font-black text-xl uppercase">Turneringer</h2>
          <div className="mt-2 overflow-x-scroll snap-mandatory snap-x whitespace-nowrap">
            <TournamentItems inline="true" />
          </div>
        </div>
        <div className="mt-4">
          <h2 className="font-black text-xl uppercase">Populære kampe</h2>
          <div className="mt-2">
            <MatchItems setBetPopupState={setBetPopupState} />
          </div>
        </div>
      </div>
      {betPopupState !== null ? <BetPopup user={props.user} state={betPopupState} setState={setBetPopupState} /> : ''}
      <NavBar />
    </div>
  );
}
