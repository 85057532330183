import React from 'react';
import TitleBar from '../components/TitleBar';
import NavBar from '../components/NavBar';
import TournamentItems from '../components/TournamentItems';
import Search from '../components/Search';

export default function Tournaments(props) {
  return (
    <div>
      <TitleBar user={props.user} setUser={props.setUser} />
      <div className="mb-32 mt-1 mx-3">
        <div className="flex justify-center">
          <div className="basis-4/5">
            <Search types={['users', 'tournaments']} placeholder="Find bruger eller turnering" />
          </div>
        </div>
        <div className="mt-4">
          <h2 className="font-black text-xl uppercase">Turneringer</h2>
          <div className="flex flex-col items-center mt-2">
            <TournamentItems />
          </div>
        </div>
      </div>
      <NavBar />
    </div>
  );
}
