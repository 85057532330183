import React from 'react';
import {Link} from 'react-router-dom';

export default function NotFound(props) {
  return (
    <div className="text-center">
      <h1 className="font-black mt-10 text-7xl w-full">
        SPIGA
        <span className="text-spiga-green">.</span>
      </h1>
      <p className="font-bold text-sm uppercase">A <span className="text-spiga-green">free</span> and <span className="text-spiga-green">social</span> sport prediction game</p>
      <div className="mt-10">
        <p className="text-lg">Den efterspurgte side blev ikke fundet.</p>
        <div>
          <Link className="border-2 border-gray-300 border-solid font-bold inline-block mt-10 p-2 rounded text-spiga-green text-xl uppercase" to="/">
            Gå til forsiden
          </Link>
        </div>
      </div>
    </div>
  );
}
